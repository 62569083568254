<template>
  <v-footer
    v-bind="localAttrs"
    :padless="padless"
    style="background-color: unset"
  >
    <v-container>
      <v-row justify="center">
        <v-col cols="12" xl="9">
          <v-container>
            <v-row justify="center">
              <v-col cols="12" class="text-center">
                <a href="https://www.tiktok.com/@flick.club" rel="nofollow" target="_blank">
                  <tik-tok-icon style="width:25px; height:25px; filter: invert(100%)" class="mx-6" />
                </a>
                <a href="https://youtube.com/@flickclub" rel="nofollow" target="_blank">
                  <you-tube-icon style="width:25px; height:25px; filter: invert(100%)" class="mx-6" />
                </a>
                <a href="https://www.pinterest.com/flickclub/" rel="nofollow" target="_blank">
                  <pinterest-icon style="width:25px; height:25px; filter: invert(100%)" class="mx-6" />
                </a>
                <a href="https://www.reddit.com/r/flickclub/" rel="nofollow" target="_blank">
                  <reddit-icon style="width:25px; height:25px; filter: invert(100%)" class="mx-6" />
                </a>
              </v-col>
              <v-col cols="12" md="4" class="text-center">
                <v-container>
                  <v-row>
                    <v-col
                      v-for="(link, index) in links"
                      :key="index"
                      cols="4"
                    >
                      <a v-if="link.text === 'Contact'" :href="link.href">
                        <b>{{ link.text }}</b>
                      </a>
                      <NuxtLink v-else :to="link.href">
                        <b>{{ link.text }}</b>
                      </NuxtLink>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
              <v-col cols="12" md="3">
                <v-container>
                  <v-row class="text-center" justify="center">
                    <v-col cols="4" sm="6">
                      <span class="grey--text text--lighten-1 text-center"><b>Data<br> Providers</b></span>
                    </v-col>
                    <v-col cols="4" sm="6">
                      <a href="https://www.openlibrary.org/" rel="nofollow" target="_blank">
                        <img src="../static/openlibrary.png" alt="openlibrary" style="width:60px">
                      </a>
                    </v-col>
                    <v-col cols="4" sm="6">
                      <a href="https://www.themoviedb.org/" rel="nofollow" target="_blank">
                        <img src="../static/tmdb.png" alt="tmdb" style="width:60px">
                      </a>
                    </v-col>
                    <v-col cols="4" sm="6">
                      <a href="https://www.igdb.com/" rel="nofollow" target="_blank">
                        <img src="../static/igdb.png" alt="igdb" style="width:60px">
                      </a>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
              <v-col cols="12">
                <div class="d-xs-block d-md-flex align-end">
                  <v-card-text class="grey--text text--darken-1 order-sm-first text-center">
                    Copyright © 2022—{{ new Date().getFullYear() }} - <b>Flick Club</b>
                  </v-card-text>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
import { TikTokIcon, PinterestIcon, YouTubeIcon, RedditIcon } from "vue-simple-icons";

export default {
  name: "Footer",
  components: {
    TikTokIcon,
    PinterestIcon,
    YouTubeIcon,
    RedditIcon,
  },
  data: () => ({
    links: [
      {
        text: "Discover",
        href: "/discover",
      },
      {
        text: "Shaker",
        href: "/shaker",
      },
      {
        text: "News",
        href: "/news",
      },
      {
        text: "Terms",
        href: "/terms",
      },
      {
        text: "Privacy",
        href: "/privacy",
      },
      {
        text: "Contact",
        href: "mailto:contact@flick.club",
      },
      {
        text: "FAQ",
        href: "/faq",
      },
      {
        text: "About Us",
        href: "/about",
      },
      {
        text: "Feedback",
        href: "/feedback",
      },
    ],
    items: [
      "default",
      "absolute",
      "fixed",
    ],
    padless: false,
    variant: "default",
    offset: true,
  }),
  computed: {
    localAttrs () {
      const attrs = {};

      if (this.variant === "default") {
        attrs.absolute = false;
        attrs.fixed = false;
      } else {
        attrs[this.variant] = true;
      }
      return attrs;
    },
  },
};
</script>
